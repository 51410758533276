export default function basicForm ({ btnTxt = { submit: 'Submit', processing: 'Processing...' } } = {}) {
    return {
        isSubmitted: false,
        isValid: false,
        async submit () {
            await this.$nextTick();
            this.isSubmitted = true;
            this.isValid = false;
        },
        validate () {
            this.isValid = 0 === this.$el.querySelectorAll('[required]:invalid').length;
        },
        get btnTxt () {
            return this.isSubmitted ? btnTxt.processing : btnTxt.submit;
        },
    }
}
