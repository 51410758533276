import braintree from "braintree-web/client";

export default ({ btnTxt, braintreeToken }) => ({
    cardholderName: null,
    cardNumber: null,
    expirationMonth: null,
    expirationYear: null,
    cvv: null,
    postalCode: null,
    isSubmitted: false,
    isValid: false,
    braintreeNonce: null,
    get btnTxt () {
        if (this.isSubmitted) { return btnTxt.submitted; }
        else if (this.isValid) { return btnTxt.pay_now; }
        else { return btnTxt.submit; }
    },
    init () {
        this.validate()
    },
    submit (event) {
        this.isSubmitted = true;

        if (!this.braintreeNonce) {
            event.preventDefault()

            braintree.create({ authorization: braintreeToken }, (error, client) => {
                if (error) {
                    alert('There was an error connecting to the payment gateway. Please try again later.');
                    this.isSubmitted = false
                    throw error
                }

                client.request({
                    endpoint: 'payment_methods/credit_cards',
                    method: 'post',
                    data: {
                        creditCard: {
                            number: this.cardNumber,
                            cardholderName: this.cardholderName,
                            expirationMonth: this.expirationMonth,
                            expirationYear: this.expirationYear,
                            cvv: this.cvv,
                            billingAddress: {
                                postalCode: this.postalCode,
                            }
                        },
                    },
                }, (error, response) => {
                    if (error) {
                        alert('We were unable to process your card. Please try again, or try a different card.');
                        this.isSubmitted = false
                        throw error
                    }

                    // save the nonce and submit the form again
                    this.braintreeNonce = response.creditCards[0].nonce
                    this.$nextTick(() => {
                        this.$refs.form.submit()
                    })
                })
            })
        }
    },
    validate () {
        this.isValid = !this.isSubmitted && 0 === this.$el.querySelectorAll('[required]:invalid').length;
    },
})
