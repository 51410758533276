import basicForm from "./basicForm";

export default function clubhousePaymentCreditCardForm () {
    return {
        ...basicForm(),
        cardType: null,
        countryCode: null,
        expirationMonth: null,
        expirationYear: null,
    }
}
