import Alpine from 'alpinejs'
import basicForm from './alpine/basicForm'
import clubhousePaymentBankAccountForm from './alpine/clubhousePaymentBankAccountForm'
import clubhousePaymentCreditCardForm from './alpine/clubhousePaymentCreditCardForm'
import datePicker from './alpine/datePicker'
import focus from '@alpinejs/focus'
import ownerplusDatePickerCell from './alpine/ownerplusDatePickerCell'
import tooltipSimple from './alpine/tooltipSimple'
import ownerplusConvertPay from './alpine/ownerplusConvertPay'
import '~/owners.css'

window.Alpine = Alpine

Alpine.plugin(focus)
Alpine.data('basicForm', basicForm)
Alpine.data('datePicker', datePicker)
Alpine.data('ownerplusDatePickerCell', ownerplusDatePickerCell)
Alpine.data('paymentBankAccountForm', clubhousePaymentBankAccountForm)
Alpine.data('paymentCreditCardForm', clubhousePaymentCreditCardForm)
Alpine.data('tooltipSimple', tooltipSimple)
Alpine.data('ownerplusConvertPay', ownerplusConvertPay)
Alpine.start()
